import { useQuery, useMutation } from "react-query";
import {
  getCourses,
  addCourse,
  getQuestions,
  addQuestion,
  deleteQuestion,
  deleteCourse,
  getAverage,
  getLogOut,
} from "../store/services/service";

export const useAddCourse = () => {
  return useMutation(addCourse);
};

export const useGetCourses = () => {
  return useQuery("getCourses", getCourses);
};

export const useGetAverage = () => {
  return useQuery("getAverage", getAverage);
};

export const useAddQuestion = () => {
  return useMutation(addQuestion);
};

export const useDeleteQuestion = () => {
  return useMutation(deleteQuestion);
};

export const useDeleteCourse = () => {
  return useMutation(deleteCourse);
};

export const useGetQuestions = (id) => {
  return useQuery("getQuestions", () => {
    return getQuestions(id);
  });
};

export const useLogOut = () => {
  return useMutation(getLogOut);
};
