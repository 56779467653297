import React from "react";
import ResultComponent from "./ResultComponent";
const Result = () => {
  return (
    <div>
      <ResultComponent />
    </div>
  );
};
export default Result;
