import { Route, Routes, BrowserRouter } from "react-router-dom";
import PinCode from "./screens/Pincode";
import Course from "./screens/Course";
import PreviousFeedbacks from "./screens/PreviousFeedback";
import Result from "./screens/Result";
import Signin from "./screens/Signin/Signin";
import { UserProvider } from "./contexts/user/user.context";
import Protected from "./routes/Protected";
import Signup from "./screens/Signup/Signup";

function App() {
  return (
    <>
      <BrowserRouter>
        <UserProvider>
          <Routes>
            {/* Protected routes  */}
            <Route
              path="/teachers-dashboard"
              element={<Protected Component={Course} />}
            />
            <Route
              path="/previous-feedback/:id"
              element={<Protected Component={PreviousFeedbacks} />}
            />
            <Route path="/result/:id/:code" element={<Result />} />
            {/* Public routes  */}
            <Route path="/signin" element={<Signin />} />
            <Route path="/signup" element={<Signup />} />
            <Route exact path="/" element={<PinCode />} />
          </Routes>
        </UserProvider>
      </BrowserRouter>
    </>
  );
}

export default App;
