import { React, useState, useEffect, useRef } from "react";
import Button from "../../common/Button";
import { useNavigate } from "react-router-dom";
import { useGetQuestion } from "../../Hooks/question.hook";
import PinInput from "react-pin-input";

const PincodeComponent = ({ question, setQuestion }) => {
  const navigate = useNavigate();
  const { mutate, data, status } = useGetQuestion();

  const [code, setCode] = useState({
    first: "",
    second: "",
    third: "",
    fourth: "",
  });

  useEffect(() => {
    if (status == "success") {
      if (data.status == 200) {
        // console.log("data: ", data.data);
        setQuestion(data.data);
      } else if (data.status == 403) {
        alert("No Question Found");
        window.location.reload();
      }
    }
  }, [data, status]);

  function handleInputChange(event) {
    const value = event.target.value;
    const name = event.target.name;

    setCode((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  }
  const clickHandler = () => {
    // console.log("pincode", Questioncode);
    // const Questioncode = value;
    const values = { questionCode: Questioncode };
    mutate(values);
  };

  const [Questioncode, setQuestionCode] = useState("");
  const pinRef = useRef(null);

  const onChange = (value) => {
    setQuestionCode(value);
  };

  return (
    <div className="w-full h-screen bg-white flex flex-col justify-center items-center px-3 md:px-0">
      <div className="flex flex-row top-1  justify-end w-full space-x-4">
        <Button
          onClick={() => {
            navigate("signin");
          }}
          text="Login"
        />
        <Button
          onClick={() => {
            navigate("signup");
          }}
          text="SignUp"
        />
      </div>
      {/* <img className="h-14 md:h-17 xl:h-20 mt-6 " src={Logo} alt="" /> */}
      <p className="text-center  font-bold pt-6 pb-12 text-[1.5rem] md:text-[5rem] xl:text-[6rem] text-primary">
        LOGO
      </p>
      <p className=" text-center  font-normal pt-6 pb-12 text-[1rem] md:text-[1.5rem] xl:text-[2rem] text-primary">
        Please Enter the code
      </p>

      <div class="py-4 px-1 sm:px-2 md:px-6 lg:px-12 bg-transparent shadow-md rounded-lg">
        <div class="flex ">
          <PinInput
            length={4}
            focus
            // disabled
            ref={pinRef}
            type="alphanumeric"
            onChange={onChange}
          />
          {/* <input
            name="first"
            class="h-16 w-12 border border-primary active:border-primary mx-2 bg-white rounded-lg flex items-center text-center font-thin text-3xl"
            maxLength={1}
            onChange={handleInputChange}
          ></input>
          <input
            name="second"
            class="h-16 w-12 border border-primary active:border-primary mx-2 bg-white rounded-lg flex items-center text-center font-thin text-3xl"
            maxLength={1}
            onChange={handleInputChange}
          ></input>
          <input
            name="third"
            class="h-16 w-12 border border-primary active:border-primary mx-2 bg-white rounded-lg flex items-center text-center font-thin text-3xl"
            maxLength={1}
            onChange={handleInputChange}
          ></input>
          <input
            name="fourth"
            class="h-16 w-12 border border-primary active:border-primary mx-2 bg-white rounded-lg flex items-center text-center font-thin text-3xl"
            maxLength={1}
            onChange={handleInputChange}
          ></input> */}
        </div>
      </div>
      <div className="py-11 ">
        <Button onClick={clickHandler} text="Submit" />
      </div>
      <p className="text-center  font-normal py-6 text-[1rem] md:text-[1.5rem] xl:text-[2rem] text-primary">
        The code is shared on screen in front of you!
      </p>
    </div>
  );
};

export default PincodeComponent;
