import React, { useState, useEffect, createContext } from "react";

const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(undefined);
  const [authentication, setAuthentication] = useState(null);

  // const { data, status } = useVerifyAccessToken();

  // const isAuthenticated = () => {
  //   if (data.status == 401) {
  //     console.log("Error", data);
  //     setAuthentication(false);
  //     return false;
  //   } else if (data.status == 200) {
  //     setCurrentUser(data.data);
  //     setAuthentication(true);
  //     console.log("success", data);
  //     return true;
  //   }
  // };

  // useEffect(() => {
  //   if (status == "success") {
  //      isAuthenticated();
  //   }
  //   console.log("authentication", authentication);
  // }, [status]);

  return (
    <UserContext.Provider
      value={{ currentUser, setCurrentUser, authentication }}
    >
      {children}
    </UserContext.Provider>
  );
};

export default UserContext;
