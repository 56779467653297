import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Button from "../../common/Button";
import FeedbackModal from "./FeedbackModal";
import {
  useGetQuestions,
  useAddQuestion,
  useDeleteQuestion,
} from "../../Hooks/teacher.hook";
import io from "socket.io-client";
import MyPieChart from "./MyPieChart";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";

// const FeedbackCount = ({ questionId }) => {
//   console.log("questionId", questionId);
//   const [feedbackCount, setFeedbackCount] = useState(null);
//   const socket = useRef(null);

//   useEffect(() => {
//     socket.current = io(`${process.env.REACT_APP_BASE_URL}`, {
//       transports: ["websocket", "polling"],
//     });

//     const getFeedbacks = () => {
//       socket.current.emit("findAllFeedback", Number(questionId));
//       socket.current.on("findAllFeedback", (newAnswers) => {
//         const count = newAnswers?.length || 0;
//         console.log("count", count);
//         setFeedbackCount(count);
//       });
//     };

//     getFeedbacks();

//     // Clean up the event listener when the component unmounts
//     return () => {
//       socket.current.off("findAllFeedback");
//     };
//   }, [questionId]);

//   return feedbackCount !== null ? feedbackCount : "Loading...";
// };

const PreviousFeedbacksComponent = () => {
  const [showModal, setShowModal] = useState(false);
  const [questions, setQuestions] = useState([]);
  const [allFeedbacks, setAllFeedbacks] = useState([]);
  const socket = io(`${process.env.REACT_APP_BASE_URL}`, {
    transports: ["websocket", "polling"],
  });
  const [feedbackCounts, setFeedbackCounts] = useState();

  const navigate = useNavigate();
  const { id } = useParams();

  const {
    data: getQuestionsData,
    status: getQuestionsStatus,
    remove,
    refetch,
  } = useGetQuestions(parseInt(id));

  const {
    mutate,
    status: addQuestionStatus,
    data: addQuestionData,
  } = useAddQuestion();

  const {
    mutate: deleteQuestion,
    status: deleteQuestionStatus,
    data: deleteQuestionData,
  } = useDeleteQuestion();

  useEffect(() => {
    if (getQuestionsStatus == "success") {
      if (getQuestionsData.status == 200) {
        // console.log("result1", getQuestionsData.data);
        setQuestions(getQuestionsData.data);
      }
    }
  }, [getQuestionsData, getQuestionsStatus]);

  useEffect(() => {
    if (deleteQuestionStatus == "success" && deleteQuestionData.status == 200) {
      refetch();
      alert("Question deleted successfully");
    }
  }, [deleteQuestionData, deleteQuestionStatus]);

  useEffect(() => {
    return () => {
      remove();
    };
  }, []);

  useEffect(() => {
    const fetchFeedbackCounts = async () => {
      const counts = [];

      for (const question of questions) {
        const feedbackCount = await new Promise((resolve) => {
          socket.emit("findAllFeedback", Number(question.id));
          socket.on("findAllFeedback", (newAnswers) => {
            const counts = {
              positive: 0,
              negative: 0,
              neutral: 0,
            };

            newAnswers.forEach((answer) => {
              // console.log("answerrr", answer);
              if (answer.sentiment === "positive") {
                counts.positive++;
              } else if (answer.sentiment === "negative") {
                counts.negative++;
              } else if (answer.sentiment === "neutral") {
                counts.neutral++;
              }
            });

            resolve(counts);
          });
        });

        counts.push(feedbackCount);
      }

      // console.log("count", counts);
      setFeedbackCounts(counts);
      // socket.off("findAllFeedback");
    };

    fetchFeedbackCounts();
  }, [questions]);

  useEffect(() => {
    if (addQuestionStatus == "success") {
      if (addQuestionData.status == 201) {
        // console.log("result", addQuestionData.data);
        alert(`The code is ${addQuestionData.data.questionCode}`);
        refetch();
      }
    }
  }, [addQuestionData, addQuestionStatus]);

  const clickHandler = (id, code) => {
    navigate(`/result/${id}/${code}`);
  };
  const handleSubmit = (values) => {
    // console.log(values);
    mutate({ question: values.question, classId: parseInt(id) });
    setShowModal(false);
  };

  const handleDelete = (id) => {
    deleteQuestion({ id: id });
  };

  const handleCancel = () => {
    setShowModal(false);
  };

  return (
    <>
      <div className="w-full h-screen bg-white flex flex-col justify-center items-center px-1 md:px-0 py-20">
      <div className="absolute top-0 left-0 mt-6 ml-6 text-primary cursor-pointer"  onClick={() => navigate(-1)}>
      <FontAwesomeIcon icon={faArrowLeft} size="2x" />
    </div>
        <Button
          onClick={() => setShowModal(true)}
          text="Ask For New Feedbacks"
        />

        <div class="shadow-md  mt-12 overflow-y-auto">
          <table class="w-[100%] text-sm text-gray-500 text-center ">
            <thead
              class="text-xs  uppercase bg-primary text-white sticky top-0
          "
            >
              <tr>
                <th
                  scope="col"
                  class="px-1 sm:px-2 md:px-4 lg:px-6 xl:px-10 py-3"
                >
                  Number
                </th>
                <th
                  scope="col"
                  class="px-1 sm:px-2 md:px-4 lg:px-6 xl:px-10 py-3"
                >
                  Code
                </th>
                <th
                  scope="col"
                  class="px-1 sm:px-2 md:px-4 lg:px-6 xl:px-10 py-3"
                >
                  Description
                </th>
                <th
                  scope="col"
                  class="px-1 sm:px-2 md:px-4 lg:px-6 xl:px-10 py-3"
                >
                  Date
                </th>

                <th
                  scope="col"
                  class="px-1 sm:px-2 md:px-4 lg:px-6 xl:px-10 py-3"
                >
                  Action
                </th>
                <th
                  scope="col"
                  class="px-1 sm:px-2 md:px-4 lg:px-6 xl:px-10 py-3"
                >
                  No. of Feebacks
                </th>
              </tr>
            </thead>
            <tbody>
              {questions && questions.length >= 1 ? (
                questions.map((question, index) => {
                  // const feedbackCount = await getFeedbacks(question.id);
                  return (
                    <tr key={index} class="bg-white border-b ">
                      <td class="px-1 sm:px-2 md:px-4 lg:px-6 xl:px-10 py-4 font-medium text-gray-900  ">
                        {index + 1}
                      </td>
                      <td class="px-1 sm:px-2 md:px-4 lg:px-6 xl:px-10 py-4 font-medium text-gray-900  ">
                        {question.questionCode}
                      </td>
                      <td class="px-1 sm:px-2 md:px-4 lg:px-6 xl:px-10 py-4 font-medium text-gray-900">
                        {question.question}
                      </td>
                      <td class="px-1 sm:px-2 md:px-4 lg:px-6 xl:px-10 py-4 font-medium text-gray-900">
                        {question.createdAt.substring(0, 10)}
                      </td>

                      <td class="px-1 sm:px-2 md:px-4 lg:px-6 xl:px-10 py-4 w-[12rem] flex justify-evenly">
                        <a
                          onClick={() => {
                            clickHandler(question.id, question.questionCode);
                          }}
                          class="font-medium text-primary cursor-pointer  hover:underline"
                        >
                          View
                        </a>
                        <a
                          onClick={() => {
                            handleDelete(question.id);
                          }}
                          class="font-medium text-primary cursor-pointer  hover:underline"
                        >
                          Delete
                        </a>
                      </td>
                      <td class="px-1 sm:px-2 md:px-4 lg:px-6 xl:px-10 py-4 font-medium text-gray-900">
                        {/* <FeedbackCount questionId={question.id} /> */}
                        {question.count}
                      </td>
                    </tr>
                  );
                })
              ) : (
                <>
                  <tr>
                    <td></td>
                    <td></td>
                    <td className="flex justify-center items-center">
                      <p className="text-lg font-bold text-center p-4">
                        No data found
                      </p>
                    </td>
                  </tr>
                </>
              )}
            </tbody>
          </table>
        </div>
        <FeedbackModal
          title="Add Batch"
          showModal={showModal}
          onClose={handleCancel}
          onSubmit={handleSubmit}
        />
        <div>
          {feedbackCounts && (
            <MyPieChart feedBacks={feedbackCounts} />
            // <>
            //   <span>
            //     postive count :{" "}
            //     {feedbackCounts?.reduce((prev, next) => prev + next.positive, 0)}
            //   </span>
            //   <span>
            //     Negative count :{" "}
            //     {feedbackCounts?.reduce((prev, next) => prev + next.negative, 0)}
            //   </span>
            //   <span>
            //     Neutral count :{" "}
            //     {feedbackCounts?.reduce((prev, next) => prev + next.neutral, 0)}
            //   </span>
            // </>
          )}
        </div>
      </div>
    </>
  );
};

export default PreviousFeedbacksComponent;
