import React from "react";
import CourseComponent from "./CourseComponent";

const Course = () => {
  return (
    <div>
      <CourseComponent />
    </div>
  );
};

export default Course;
