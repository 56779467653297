import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar as solidStar } from "@fortawesome/free-solid-svg-icons";
const RatingStars = ({ fillColor, emptyColor, size, setRating }) => {
  const [hoverRating, setHoverRating] = useState(0);

  return (
    <div className="flex items-center space-x-1">
      {[...Array(5)].map((_, index) => (
        <FontAwesomeIcon
          key={index}
          icon={solidStar}
          color={fillColor}
          style={{
            width: `${size}px`,
            height: `${size}px`,
            color: hoverRating >= index + 1 ? fillColor : emptyColor,
            cursor: "pointer",
          }}
          onClick={(event) => {
            // console.log(
            //   parseInt(event.currentTarget.getAttribute("data-index"))
            // );
            setHoverRating(
              parseInt(event.currentTarget.getAttribute("data-index"))
            );

            setRating(parseInt(event.currentTarget.getAttribute("data-index")));
          }}
          data-index={index + 1}
        />
      ))}
    </div>
  );
};

export default RatingStars;
