import { React, useState } from "react";
import Button from "../../common/Button";

import RatingStars from "./RatingStars";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import io from "socket.io-client";

const QuestionComponent = ({ question }) => {
  const [feedback, setFeedback] = useState();
  const [rating, setRating] = useState(0);

  const socket = io(`${process.env.REACT_APP_BASE_URL}`, {
    transports: ["websocket", "polling"],
  });

  const handleAnswerSubmit = (event) => {
    event.preventDefault();
    const answer = {
      questionId: question.id,
      feedback: feedback,
      rating: rating,
    };
    socket.emit("createFeedback", answer);
    setFeedback("");
    alert("Feedback Submitted successfully");
    window.location.reload();
  };

  const handleRatingChange = (newRating) => {
    setRating(newRating);
  };
  const handleChange = (event) => {
    const words = event.target.value.split(" ");
    // if (words.length <= 10) {
    // console.log("Value", event.target.value, words.length);
    setFeedback(event.target.value);
    // } else {
    //   console.log("feedback", words.slice(0, 10).join(" "));
    //   setFeedback(words.slice(0, 10).join(" "));
    // }
  };
  // console.log(rating);

  return (
    <div className="w-full h-screen bg-white flex flex-col justify-center items-center px-2 md:px-0">
      <p className=" text-center  font-normal py-6 text-[1rem] md:text-[1.5rem] xl:text-[2rem] text-primary">
        Please give your feedback for following question:
      </p>

      <p className="text-center text-sm md:text-lg pb-6">{question.question}</p>
      <textarea
        rows="8"
        style={{ resize: "none" }}
        value={feedback}
        onChange={handleChange}
        // maxLength={50}
        placeholder="Write your feedback here..."
        className="block p-2.5 w-full sm:w-80 md:w-[700px] xl:[800px] text-lg text-gray-600 bg-white shadow-lg shadow-primary  rounded-lg border border-gray-300 focus:ring-gray-300 focus:border-gray-300 "
      />

      <div className="flex justify-between space-x-[26rem] pt-5">
        <div className="flex flex-col">
          <p className="text-sm md:text-lg pb-2">Choose rating:</p>

          <RatingStars
            fillColor="#07285D"
            emptyColor="gray"
            size="23"
            setRating={handleRatingChange}
          />
        </div>
        <div>{/* <p className="text-sm md:text-lg ">Max words 50</p> */}</div>
      </div>
      <div className="pt-16">
        <Button onClick={handleAnswerSubmit} text="Submit" />
      </div>
    </div>
  );
};

export default QuestionComponent;
