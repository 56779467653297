import axios from "axios";
import Cookies from "js-cookie";

// export const headerKeys = {
//   AccessToken: "Authorization",
//   Expiry: "expiry",
//   TokenType: "token-type",
//   Uid: "uid",
//   Client: "client",
//   ContentType: "Content-Type",
// };

const api = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  // baseURL: "https://back-student-feedback.herokuapp.com",
  withCredentials: true,
});

// api.interceptors.request.use((config) => {
//   // const token = getApiHeaders();
//   //   console.
//   if (config.headers && token) {
//     console.log("Application/json");
//     config.headers[headerKeys.AccessToken] = "Bearer " + token;
//   } else if (!token) {
//     console.log("Multipart form data");
//     config.headers[headerKeys.ContentType] = "multipart/form-data";
//   }
//   return config;
// });

// api.interceptors.response.use(
//   function (response) {
//     return response.data;
//   },
//   function (error) {
//     console.log("error", error);

//     if (error.response) {
//       console.log("error", error.response);
//       // The request was made and the server responded with a status code
//       // that falls out of the range of 2xx
//     } else if (error.request) {
//       // The request was made but no response was received
//       // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
//       // http.ClientRequest in node.js
//     } else {
//       // Something happened in setting up the request that triggered an Error
//     }
//     // return Promise.reject(error);
//     return Promise.reject(error);
//   }
// );

// function isTokenError(error) {
//   const headers = getApiHeaders();
//   return headers && error.response && error.response.status === 401;
// }

// function handleTokenError() {
//   // clearData();
//   //   store.dispatch(getLogoutAction());
// }

export const postRequest = async (url, body) => {
  try {
    const response = await api.post(url, body);
    return response;
  } catch (error) {
    return { data: {}, status: error.response.status };
  }
};

export function patchRequest(url, body) {
  return new Promise((resolve, reject) => {
    api
      .patch(url, body)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function putRequest(url, body) {
  return new Promise((resolve, reject) => {
    api
      .put(url, body)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export async function getRequest(url, params) {
  // console.log("Params from API", params);
  // console.log(url);
  try {
    const response = await api.get(url, params);
    return response;
  } catch (error) {
    return { data: {}, status: error.response.status };
  }
}

export function deleteRequest(url, body) {
  return new Promise((resolve, reject) => {
    api
      .delete(url, { data: body })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}
