import { useQuery, useMutation, QueryClient } from "react-query";
import {
  loginWithEmail,
  signupWithEmail,
  getTeacher,
} from "../store/services/service";

export const useSignup = () => {
  return useMutation(signupWithEmail);
};

export const useLogin = () => {
  return useMutation(loginWithEmail);
};

export const useVerifyAccessToken = () => {
  return useQuery("verifyAccessToken", getTeacher);
};
