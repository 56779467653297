import React from "react";

const AverageRatings = ({ averageRating }) => {
  // Calculate the fill percentage based on the average rating
  // console.log("Ratings", averageRating);
  const maxRating = 5;
  const filledStars = Math.floor(averageRating);
  const remainingStars = maxRating - filledStars;

  return (
    <div className="flex">
      {[...Array(filledStars)].map((_, index) => (
        <span key={index} className="text-#07285D text-xl">
          &#9733;
        </span>
      ))}
      {[...Array(remainingStars)].map((_, index) => (
        <span key={index} className="text-gray-400 text-xl">
          &#9733;
        </span>
      ))}
    </div>
  );
};

export default AverageRatings;
