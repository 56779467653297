import { useState, useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useLogin } from "../../Hooks/auth.hook";
import { useNavigate } from "react-router";
import { useVerifyAccessToken } from "../../Hooks/auth.hook";

const Signin = () => {
  const [verified, setVerified] = useState(null);
  const {
    data: verifyData,
    status: verificationStatus,
    remove,
  } = useVerifyAccessToken();

  const [isAnimating, setIsAnimating] = useState(false);
  const { mutate, status, isError, data } = useLogin();
  const navigate = useNavigate();

  useEffect(() => {
    if (verificationStatus == "success" && verifyData.status == 200) {
      navigate("/teachers-dashboard");
      // console.log("data: ", verifyData.data);
    } else {
      setVerified(false);
    }
  }, [verifyData, verificationStatus]);

  useEffect(() => {
    if (status == "success") {
      if (data.status == 200) {
        navigate("/teachers-dashboard");
        // console.log("data: ", data);
      }
    }
  }, [data, status]);

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: Yup.object({
      email: Yup.string().email().required("Email is required"),
      password: Yup.string().required("Password is required"),
    }),
    onSubmit: (values) => {
      mutate(values);
    },
  });

  const handleModalAnimationEnd = () => {
    setIsAnimating(false);
  };

  return (
    <>
      {verified == false ? (
        <div
          className={`fixed z-50 top-0 left-0 w-full h-full bg-white flex justify-center items-center ${
            isAnimating ? "modal-form active" : "modal-form"
          }`}
          onAnimationEnd={handleModalAnimationEnd}
        >
          <div className="modal-content p-6 rounded-lg shadow-lg backdrop-filter backdrop-blur-lg bg-opacity-50 bg-gray-300">
            <div className="flex justify-between items-center mb-6">
              <h2 className="text-lg font-bold text-primary">Login Form</h2>
            </div>
            <div>
              <form onSubmit={formik.handleSubmit}>
                <div className="mb-4">
                  <label
                    htmlFor="email"
                    className="block text-primary font-bold mb-2"
                  >
                    Email:
                  </label>
                  <input
                    type="text"
                    id="email"
                    name="email"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.email}
                    className={`appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${
                      formik.touched.email && formik.errors.email
                        ? "border-red-500"
                        : "border-gray-400"
                    }`}
                  />
                  {formik.touched.email && formik.errors.email ? (
                    <p className="text-red-500 text-xs italic">
                      {formik.errors.email}
                    </p>
                  ) : null}
                </div>
                <div className="mb-4">
                  <label
                    htmlFor="password"
                    className="block text-primary font-bold mb-2"
                  >
                    Password:
                  </label>
                  <input
                    type="password"
                    id="password"
                    name="password"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.password}
                    className={`appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${
                      formik.touched.password && formik.errors.password
                        ? "border-red-500"
                        : "border-gray-400"
                    }`}
                  />
                  {formik.touched.password && formik.errors.password ? (
                    <p className="text-red-500 text-xs italic">
                      {formik.errors.password}
                    </p>
                  ) : null}
                </div>
                <div className="flex justify-end">
                  <button
                    type="button"
                    onClick={() => {
                      navigate("/signup");
                    }}
                    className="text-gray-500 mr-4"
                  >
                    Register
                  </button>
                  <button
                    type="submit"
                    className="bg-primary hover:bg-hoverColor  text-white font-bold py-2 px-4 rounded"
                  >
                    Sign In
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default Signin;
