import { React, useState, useEffect } from "react";
import io from "socket.io-client";
import { useParams, useNavigate } from "react-router-dom";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Cell,
} from "recharts";
import RatingStars from "../Pincode/RatingStars";
import AverageRatings from "./AverageRatings";
import MyFeedbacksTable from "./MyFeedbacksTable";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";

const ResultComponent = () => {
  const [answers, setAnswers] = useState([]);
  const [positive, setPositive] = useState();
  const [negative, setNegative] = useState();
  const [neutral, setNeutral] = useState();
  const [showTable, setShowTable] = useState(true);
  const [averageRating, setAverageRating] = useState();

  const navigate = useNavigate();

  const { id, code } = useParams();
  console.log(id, code);
  const [questionId, setQuestionId] = useState(id);
  const socket = io(`${process.env.REACT_APP_BASE_URL}`, {
    transports: ["websocket", "polling"],
  });

  useEffect(() => {
    socket.emit("findAllFeedback", Number(questionId));

    socket.on("findAllFeedback", (newAnswers) => {
      if (newAnswers[0].questionId == id) {
        // console.log("1", newAnswers);
        setAnswers(newAnswers);
      }
    });

    socket.on("createFeedback", (newAnswers) => {
      if (newAnswers[0].questionId == id) {
        // console.log("2", newAnswers);
        setAnswers(newAnswers);
      }
    });

    return () => {
      socket.off("newFeedback");
    };
  }, []);

  useEffect(() => {
    // console.log("ans", answers);
    const countPositive = answers.filter((answer) => {
      return answer.sentiment === "positive";
    }).length;
    setPositive(countPositive);
    const countNegative = answers.filter((answer) => {
      return answer.sentiment === "negative";
    }).length;
    setNegative(countNegative);
    const countNeutral = answers.filter((answer) => {
      return answer.sentiment === "neutral";
    }).length;
    setNeutral(countNeutral);
    if (answers.length > 0) {
      const countRating = answers.reduce((a, b) => a + b.rating, 0);
      // console.log("countRating", countRating, answers.length);
      setAverageRating(countRating / answers.length);
    } else {
      setAverageRating(0);
    }
  }, [answers]);

  const colors = ["#27AE60", "#839192", "#FF3131"];
  const data = [
    {
      name: "Positive",
      feedbacks: positive,
    },
    {
      name: "Neutral",
      feedbacks: neutral,
    },
    {
      name: "Negative",
      feedbacks: negative,
    },
  ];

  return (
    <div className="w-full h-screen bg-white flex flex-col justify-start items-center px-2 md:px-0">
      <div
        className="absolute top-0 left-0 mt-6 ml-6 text-primary cursor-pointer"
        onClick={() => navigate(-1)}
      >
        <FontAwesomeIcon icon={faArrowLeft} size="2x" />
      </div>
      <p className="text-center font-normal pt-10 text-[0.5rem] md:text-[1rem] xl:text-[1.5rem] text-primary">
        Join www.studentfeedbacks.com with code
        <span className="font-semibold text-[1rem] md:text-[1.5rem] xl:text-[2rem]">
          {" "}
          {code}{" "}
        </span>
        for providing feedback.
      </p>
      <div className="flex flex-row text-center font-normal py-3 text-[0.5rem] md:text-[1rem] xl:text-[1.5rem] text-primary">
        {/* <p>Chart</p> */}
        {averageRating && (
          <>
            <span className="mt-1 mr-2">
              Average Ratings: {parseFloat(averageRating).toFixed(1)}
            </span>
            <div className="mt-2">
              <AverageRatings averageRating={averageRating} />
            </div>
          </>
        )}
      </div>

      <ResponsiveContainer width="50%" height="50%">
        <BarChart width={150} height={40} data={data} barSize={100}>
          <XAxis
            dataKey="name"
            scale="point"
            padding={{ left: 70, right: 70 }}
          />
          <YAxis />
          <Tooltip />
          <Legend />
          <CartesianGrid strokeDasharray="3 3" />
          <Bar dataKey="feedbacks" fill={colors} background={{ fill: "#eee" }}>
            {data.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={colors[index]} />
            ))}
          </Bar>
        </BarChart>
      </ResponsiveContainer>
      <div className="flex flex-row mt-2">
        <p
          className={
            showTable == true
              ? "mr-10 bg-slate-300 p-2 cursor-pointer rounded-md"
              : "mr-10 p-2 cursor-pointer"
          }
          onClick={() => setShowTable(true)}
        >
          Show Feedbacks
        </p>
        <p
          className={
            showTable == false
              ? "cursor-pointer bg-slate-300 p-2 rounded-md"
              : "p-2 cursor-pointer"
          }
          onClick={() => setShowTable(false)}
        >
          Hide Feedbacks
        </p>
      </div>
      {showTable == true && <MyFeedbacksTable answers={answers} />}
    </div>
  );
};

export default ResultComponent;
