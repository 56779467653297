import React from "react";

const Button = ({ onClick, text }) => {
  return (
    <button
      onClick={onClick}
      class="bg-primary hover:bg-hoverColor text-white text-lg font-light py-3 px-7 border border-hoverColor rounded-full"
    >
      {text}
    </button>
  );
};

export default Button;
