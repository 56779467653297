export const USER_LOGIN = "auth/local/signin";
export const USER_REGISTER = "auth/local/signup";
export const FORGET_PASSWORD = "/forgetpassword";
export const USER_LOGOUT = "auth/logout";
export const SESSION_CHECK = "/session_check";
export const GET_COURSES = "/teacher/get-all-classes";
export const ADD_COURSE = "/teacher/add-class";
export const GET_QUESTIONS = (id) => `/teacher/get-all-questions/${id}`;
export const ADD_QUESTION = "/teacher/add-question";
export const DELETE_QUESTION = "/teacher/delete-question";
export const DELETE_COURSE = "/teacher/delete-course";
export const GET_QUESTION = "/get-question";
export const GET_TEACHER = "/auth/verify-access-token";
export const GET_AVERAGE = "/teacher/get-average";
