import React from "react";

const MyFeedbacksTable = ({ answers }) => {
  return (
    <>
      <div class="shadow-md overflow-y-auto mt-2 mb-7">
        <table class="w-[100%] text-sm text-gray-500 text-center ">
          <thead
            class="text-xs  uppercase bg-primary text-white sticky top-0
          "
          >
            <tr>
              <th
                scope="col"
                class="px-1 sm:px-2 md:px-4 lg:px-6 xl:px-10 py-3"
              >
                Number
              </th>
              <th
                scope="col"
                class="px-1 sm:px-2 md:px-4 lg:px-6 xl:px-10 py-3"
              >
                Description
              </th>
              <th
                scope="col"
                class="px-1 sm:px-2 md:px-4 lg:px-6 xl:px-10 py-3"
              >
                Sentiment
              </th>
              <th
                scope="col"
                class="px-1 sm:px-2 md:px-4 lg:px-6 xl:px-10 py-3"
              >
                Date
              </th>
            </tr>
          </thead>
          <tbody>
            {answers && answers.length >= 1 ? (
              answers.map((answer, index) => {
                // const feedbackCount = await getFeedbacks(question.id);
                return (
                  <tr key={index} class="bg-white border-b ">
                    <td class="px-1 sm:px-2 md:px-4 lg:px-6 xl:px-10 py-3 font-medium text-gray-900  ">
                      {index + 1}
                    </td>
                    <td class="px-1 sm:px-2 md:px-4 lg:px-6 xl:px-10 py-3 font-medium text-gray-900  ">
                      {answer.description}
                    </td>
                    <td class="px-1 sm:px-2 md:px-4 lg:px-6 xl:px-10 py-3 font-medium text-gray-900">
                      {answer.sentiment}
                    </td>
                    <td class="px-1 sm:px-2 md:px-4 lg:px-6 xl:px-10 py-3 font-medium text-gray-900">
                      {answer.createdAt.substring(0, 10)}
                    </td>
                  </tr>
                );
              })
            ) : (
              <>
                <tr>
                  <td></td>
                  <td></td>
                  <td className="flex justify-center items-center">
                    <p className="text-lg font-bold text-center p-4">
                      No data found
                    </p>
                  </td>
                </tr>
              </>
            )}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default MyFeedbacksTable;
