import { React, useState, useEffect } from "react";
import PincodeComponent from "./PincodeComponent";
import QuestionComponent from "./QuestionComponent";

const Pincode = () => {
  const [question, setQuestion] = useState(null);
  useEffect(() => {
    // console.log("question", question);
  }, [question]);
  return (
    <div>
      {question ? (
        <QuestionComponent question={question} />
      ) : (
        <PincodeComponent question={question} setQuestion={setQuestion} />
      )}
    </div>
  );
};

export default Pincode;
