import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { useFormik } from "formik";
import * as Yup from "yup";

const FeedbackModal = ({ showModal, onClose, onSubmit }) => {
  const [showAlert, setShowAlert] = useState(false);
  const [isAnimating, setIsAnimating] = useState(false);

  const formik = useFormik({
    initialValues: {
      question: "",
    },
    validationSchema: Yup.object({
      question: Yup.string().required("Question is required"),
    }),
    onSubmit: (values) => {
      // console.log(values);
      onSubmit(values);
    },
  });

  const handleModalAnimationEnd = () => {
    setIsAnimating(false);
  };

  return (
    <>
      {showModal ? (
        <div
          className={`fixed z-50 top-0 left-0 w-full h-full bg-primary bg-opacity-50 flex justify-center items-center ${
            isAnimating ? "modal-form active" : "modal-form"
          }`}
          onAnimationEnd={handleModalAnimationEnd}
        >
          <div className="modal-content p-6 rounded-lg shadow-lg backdrop-filter backdrop-blur-lg bg-opacity-50 bg-white">
            <div className="flex justify-between items-center mb-6">
              <h2 className="text-lg font-bold text-primary">
                Feedback Details
              </h2>
              <button onClick={onClose} className="text-gray-500">
                <FontAwesomeIcon
                  icon={faXmark}
                  color={"gray"}
                  className="h-6 w-6"
                />
              </button>
            </div>
            <div>
              <form onSubmit={formik.handleSubmit}>
                <div className="mb-4">
                  <label
                    htmlFor="question"
                    className="block text-primary font-bold mb-2"
                  >
                    Question:
                  </label>
                  <textarea
                    type="text"
                    id="question"
                    name="question"
                    style={{ resize: "none" }}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.question}
                    className={`appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${
                      formik.touched.question && formik.errors.question
                        ? "border-red-500"
                        : "border-gray-400"
                    }`}
                  />
                  {formik.touched.question && formik.errors.question ? (
                    <p className="text-red-500 text-xs italic">
                      {formik.errors.question}
                    </p>
                  ) : null}
                </div>

                <div className="flex justify-end">
                  <button
                    type="button"
                    onClick={onClose}
                    className="text-gray-500 mr-4"
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    className="bg-primary hover:bg-hoverColor  text-white font-bold py-2 px-4 rounded"
                  >
                    Add
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      ) : null}
      {showAlert && (
        <div
          className="fixed top-0 left-0 right-0 bottom-0 flex justify-center items-center"
          onClick={() => setShowAlert(false)}
        >
          <div className="bg-white  backdrop-filter backdrop-blur-lg bg-opacity-50 rounded-lg shadow-lg p-6 mx-4 md:mx-0 max-w-sm text-center ">
            <h2 className="text-xl font-bold mb-4 text-primary">
              Feedback Code!
            </h2>
            <p className="text-gray-700">1234</p>
            <button
              className="mt-4 bg-primary text-white px-6 py-3 rounded-full shadow-lg hover:bg-hoverColor transition-all duration-300"
              onClick={() => setShowAlert(false)}
            >
              Close
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default FeedbackModal;
