import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { useFormik } from "formik";
import * as Yup from "yup";

const faculties = [
  {
    faculty: "Faculty of Architecture and Design (AD)",
    departments: [
      { value: "Trondheim Academy of Fine Art" },
      { value: "Department of Design" },
      { value: "Department of Architecture and Technology" },
      { value: "Department of Architecture and Planning" },
    ],
  },
  {
    faculty: "Faculty of Humanities (HF)",
    departments: [
      { value: "Department of Philosophy and Religious Studies" },
      { value: "Department of Historical and Classical Studies" },
      { value: "Department of Modern History and Society" },
      { value: "Department of Art and Media Studies" },
      { value: "Department of Music" },
      { value: "Department of Language and Literature" },
      { value: "Department of Interdisciplinary Studies of Culture" },
    ],
  },
  {
    faculty:
      "Faculty of Information Technology and Electrical Engineering (IE)",
    departments: [
      { value: "Department of Computer Science" },
      { value: "Department of Electric Energy" },
      { value: "Department of Electronic Systems" },
      { value: "Department of ICT and Natural Sciences" },
      {
        value:
          "Department of Information Security and Communication Technology",
      },
      { value: "Department of Mathematical Sciences" },
      { value: "Department of Engineering Cybernetics" },
    ],
  },
  {
    faculty: "Faculty of Engineering (IV)",
    departments: [
      { value: "Department of Energy and Process Engineering" },
      { value: "Department of Marine Technology" },
      { value: "Department of Geoscience and Petroleum" },
      { value: "Department of Civil and Environmental Engineering" },
      { value: "Department of Structural Engineering" },
      { value: "Department of Mechanical and Industrial Engineering" },
      { value: "Department of Ocean Operations and Civil Engineering" },
      { value: "Department of Manufacturing and Civil Engineering" },
    ],
  },
  {
    faculty: "Faculty of Medicine and Health Sciences (MH)",
    departments: [
      { value: "Department of Health Sciences in Gjøvik" },
      { value: "Department of Health Sciences in Ålesund" },
      { value: "Kavli Institute for Systems Neuroscience" },
      { value: "Department of Clinical and Molecular Medicine" },
      { value: "Department of Neuromedicine and Movement Science" },
      { value: "Department of Mental Health" },
      { value: "Department of Public Health and Nursing" },
      { value: "Department of Circulation and Medical Imaging" },
    ],
  },
  {
    faculty: "Faculty of Natural Sciences (NV)",
    departments: [
      { value: "Department of Biology" },
      { value: "Department of Biological Sciences Ålesund" },
      { value: "Department of Biotechnology and Food Science" },
      { value: "Department of Biomedical Laboratory Science" },
      { value: "Department of Physics" },
      { value: "Department of Chemistry" },
      { value: "Department of Chemical Engineering" },
      { value: "Department of Materials Science and Engineering" },
    ],
  },
  {
    faculty: "Faculty of Social and Educational Sciences (SU)",
    departments: [
      { value: "Department of Geography" },
      { value: "Department of Teacher Education" },
      { value: "Department of Education and Lifelong Learning" },
      { value: "Department of Social Work" },
      { value: "Department of Sociology and Political Science" },
      { value: "Department of Psychology" },
      { value: "Department of Social Anthropology" },
    ],
  },
  {
    faculty: "Faculty of Economics and Management (OK)",
    departments: [
      { value: "NTNU Business School" },
      { value: "Department of International Business" },
      { value: "Department of Industrial Economics and Technology Management" },
      { value: "Department of Economics" },
    ],
  },
  {
    faculty: "NTNU University Museum (VM)",
    departments: [
      { value: "Department of Natural History" },
      { value: "Department of Archaeology and Cultural History" },
    ],
  },
];

const ModalForm = ({ title, showModal, onClose, onSubmit }) => {
  const [isAnimating, setIsAnimating] = useState(false);

  const formik = useFormik({
    initialValues: {
      semester: "fall",
      course_code: "",
      department: "",
      faculty: "",
    },
    validationSchema: Yup.object({
      semester: Yup.string().required("semester is required"),
      course_code: Yup.string().required("course code is required"),
      department: Yup.string().required("Department is required"),
      faculty: Yup.string().required("Faculty is required"),
    }),
    onSubmit: (values) => {
      onSubmit(values);
    },
  });

  const handleModalAnimationEnd = () => {
    setIsAnimating(false);
  };

  const handleFacultyChange = (event) => {
    const selectedFaculty = event.target.value;
    formik.setFieldValue("faculty", selectedFaculty);

    // Reset department when faculty changes
    formik.setFieldValue("department", "");
  };

  return (
    <>
      {showModal ? (
        <div
          className={`fixed z-50 top-0 left-0 w-full h-full bg-primary bg-opacity-50 flex justify-center items-center ${
            isAnimating ? "modal-form active" : "modal-form"
          }`}
          onAnimationEnd={handleModalAnimationEnd}
        >
          <div className="modal-content p-6 rounded-lg shadow-lg backdrop-filter backdrop-blur-lg bg-opacity-50 bg-white">
            <div className="flex justify-between items-center mb-6">
              <h2 className="text-lg font-bold text-primary">Form</h2>
              <button onClick={onClose} className="text-gray-500">
                <FontAwesomeIcon
                  icon={faXmark}
                  color={"gray"}
                  className="h-6 w-6"
                />
              </button>
            </div>
            <div>
              <form onSubmit={formik.handleSubmit}>
                <div className="mb-4">
                  <label
                    htmlFor="semester"
                    className="block text-primary font-bold mb-2"
                  >
                    Semester:
                  </label>
                  <select
                    id="semester"
                    name="semester"
                    onChange={formik.handleChange}
                    // onBlur={formik.handleBlur}
                    value={formik.values.semester}
                    className={`appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${
                      formik.touched.semester && formik.errors.semester
                        ? "border-red-500"
                        : "border-gray-400"
                    }`}
                  >
                    <option value={"fall"}>Fall</option>
                    <option value={"spring"}>Spring</option>
                  </select>
                  {formik.touched.semester && formik.errors.semester ? (
                    <p className="text-red-500 text-xs italic">
                      {formik.errors.semester}
                    </p>
                  ) : null}
                </div>
                <div className="mb-4">
                  <label
                    htmlFor="course_code"
                    className="block text-primary font-bold mb-2"
                  >
                    Course Code:
                  </label>
                  <input
                    type="text"
                    id="course_code"
                    name="course_code"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.course_code}
                    className={`appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${
                      formik.touched.course_code && formik.errors.course_code
                        ? "border-red-500"
                        : "border-gray-400"
                    }`}
                  />
                  {formik.touched.course_code && formik.errors.course_code ? (
                    <p className="text-red-500 text-xs italic">
                      {formik.errors.course_code}
                    </p>
                  ) : null}
                </div>
                <div className="mb-4">
                  <label
                    htmlFor="faculty"
                    className="block text-primary font-bold mb-2"
                  >
                    Faculty:
                  </label>
                  <select
                    id="faculty"
                    name="faculty"
                    value={formik.values.faculty}
                    onChange={handleFacultyChange}
                    className="w-[30rem] mb-2"
                  >
                    <option value="">-- Select Faculty --</option>
                    {faculties.map((faculty, index) => (
                      <option key={index} value={faculty.faculty}>
                        {faculty.faculty}
                      </option>
                    ))}
                  </select>
                  {formik.touched.faculty && formik.errors.faculty ? (
                    <p className="text-red-500 text-xs italic">
                      {formik.errors.faculty}
                    </p>
                  ) : null}
                  {formik.values.faculty && (
                    <>
                      <label
                        htmlFor="department"
                        className="block text-primary font-bold mb-2"
                      >
                        Department:
                      </label>
                      <div>
                        <select
                          id="department"
                          name="department"
                          value={formik.values.department}
                          onChange={formik.handleChange}
                          className="w-[30rem] mb-2"
                        >
                          <option value="">-- Select Department --</option>
                          {faculties
                            .find(
                              (faculty) =>
                                faculty.faculty === formik.values.faculty
                            )
                            .departments.map((department, index) => (
                              <option key={index} value={department.value}>
                                {department.value}
                              </option>
                            ))}
                        </select>
                        {formik.touched.department &&
                        formik.errors.department ? (
                          <p className="text-red-500 text-xs italic">
                            {formik.errors.department}
                          </p>
                        ) : null}
                      </div>
                    </>
                  )}
                  {/* <input
                    type="text"
                    id="department"
                    name="department"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.department}
                    className={`appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${
                      formik.touched.department && formik.errors.department
                        ? "border-red-500"
                        : "border-gray-400"
                    }`}
                  /> */}
                </div>
                <div className="flex justify-end">
                  <button
                    type="button"
                    onClick={onClose}
                    className="text-gray-500 mr-4"
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    className="bg-primary hover:bg-hoverColor  text-white font-bold py-2 px-4 rounded"
                  >
                    Add
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default ModalForm;
