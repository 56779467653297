import { postRequest, getRequest } from "./api";
import {
  FORGET_PASSWORD,
  USER_LOGIN,
  USER_REGISTER,
  USER_LOGOUT,
  SESSION_CHECK,
  GET_COURSES,
  GET_TEACHER,
  ADD_COURSE,
  GET_QUESTIONS,
  ADD_QUESTION,
  DELETE_QUESTION,
  GET_QUESTION,
  DELETE_COURSE,
  GET_AVERAGE,
} from "./endpoints";

export const signupWithEmail = (data) => postRequest(USER_REGISTER, data);

export const loginWithEmail = (data) => postRequest(USER_LOGIN, data);

export const forgetPassword = (data) => postRequest(FORGET_PASSWORD, data);

export const checkSession = () => getRequest(SESSION_CHECK);

// export const getUserLogout = () => getRequest(USER_LOGOUT);

export const getCourses = () => getRequest(GET_COURSES);

export const getAverage = () => getRequest(GET_AVERAGE);

export const addCourse = (data) => postRequest(ADD_COURSE, data);

export const getQuestions = async (id) => await getRequest(GET_QUESTIONS(id));

export const addQuestion = (data) => postRequest(ADD_QUESTION, data);

export const deleteQuestion = (data) => postRequest(DELETE_QUESTION, data);

export const deleteCourse = (data) => postRequest(DELETE_COURSE, data);

export const getQuestion = (data) => postRequest(GET_QUESTION, data);

export const getTeacher = () => getRequest(GET_TEACHER);

export const getLogOut = () => postRequest(USER_LOGOUT);
