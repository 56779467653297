import React, { useEffect, useState } from "react";
import { Cell, Legend, Pie, PieChart, Tooltip } from "recharts";

const MyPieChart = ({ feedBacks }) => {
  const COLORS = ["#00C49F", "#FF4500", "#8884d8"];
  const [data, setData] = useState();
  useEffect(() => {
    if (feedBacks?.length > 0) {
      const charData = [
        {
          name: "Postive",
          value: feedBacks.reduce((prev, next) => prev + next.positive, 0),
        },
        {
          name: "Negative",
          value: feedBacks.reduce((prev, next) => prev + next.negative, 0),
        },
        {
          name: "Neutral",
          value: feedBacks.reduce((prev, next) => prev + next.neutral, 0),
        },
      ];
      setData(charData);
    }
  }, [feedBacks]);

  return (
    <div>
      {data ? (
        <PieChart width={400} height={320}>
          <Pie
            data={data}
            cx={200}
            cy={150}
            innerRadius={60}
            outerRadius={80}
            fill="#8884d8"
            dataKey="value"
          >
            {data.map((entry, index) => (
              <Cell key={index} fill={COLORS[index % COLORS.length]} />
            ))}
          </Pie>
          <Legend verticalAlign="bottom" align="center" layout="vertical" />
          <Tooltip />
        </PieChart>
      ) : feedBacks?.length > 0 ? (
        <p>Loading...</p>
      ) : null}
    </div>
  );
};

export default MyPieChart;
