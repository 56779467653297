import React from "react";
import PreviousFeedbacksComponent from "./PreviousFeedbacksComponent";
const PreviousFeedback = () => {
  return (
    <div>
      <PreviousFeedbacksComponent />
    </div>
  );
};

export default PreviousFeedback;
