import { useState, useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useSignup } from "../../Hooks/auth.hook";
import { useNavigate } from "react-router";

const Signup = () => {
  const [isAnimating, setIsAnimating] = useState(false);
  const { mutate, status, isError, data } = useSignup();
  const navigate = useNavigate();

  useEffect(() => {
    if (status == "success") {
      if (data.status == 201) {
        navigate("/signin");
        // console.log("data: ", data);
      }
    }
  }, [data, status]);

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      password: "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Name is required"),
      email: Yup.string().email().required("Email is required"),
      password: Yup.string().required("Password is required"),
    }),
    onSubmit: (values) => {
      mutate(values);
    },
  });

  const handleModalAnimationEnd = () => {
    setIsAnimating(false);
  };

  return (
    <>
      <div
        className={`fixed z-50 top-0 left-0 w-full h-full bg-white flex justify-center items-center ${
          isAnimating ? "modal-form active" : "modal-form"
        }`}
        onAnimationEnd={handleModalAnimationEnd}
      >
        <div className="modal-content p-6 rounded-lg shadow-lg backdrop-filter backdrop-blur-lg bg-opacity-50 bg-gray-300">
          <div className="flex justify-between items-center mb-6">
            <h2 className="text-lg font-bold text-primary">Login Form</h2>
          </div>
          <div>
            <form onSubmit={formik.handleSubmit}>
              <div className="mb-4">
                <label
                  htmlFor="name"
                  className="block text-primary font-bold mb-2"
                >
                  Name:
                </label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.name}
                  className={`appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${
                    formik.touched.name && formik.errors.name
                      ? "border-red-500"
                      : "border-gray-400"
                  }`}
                />
                {formik.touched.name && formik.errors.name ? (
                  <p className="text-red-500 text-xs italic">
                    {formik.errors.name}
                  </p>
                ) : null}
              </div>

              <div className="mb-4">
                <label
                  htmlFor="email"
                  className="block text-primary font-bold mb-2"
                >
                  Email:
                </label>
                <input
                  type="text"
                  id="email"
                  name="email"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.email}
                  className={`appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${
                    formik.touched.email && formik.errors.email
                      ? "border-red-500"
                      : "border-gray-400"
                  }`}
                />
                {formik.touched.email && formik.errors.email ? (
                  <p className="text-red-500 text-xs italic">
                    {formik.errors.email}
                  </p>
                ) : null}
              </div>

              <div className="mb-4">
                <label
                  htmlFor="password"
                  className="block text-primary font-bold mb-2"
                >
                  Password:
                </label>
                <input
                  type="password"
                  id="password"
                  name="password"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.password}
                  className={`appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${
                    formik.touched.password && formik.errors.password
                      ? "border-red-500"
                      : "border-gray-400"
                  }`}
                />
                {formik.touched.password && formik.errors.password ? (
                  <p className="text-red-500 text-xs italic">
                    {formik.errors.password}
                  </p>
                ) : null}
              </div>
              <div className="flex justify-end">
                <button
                  type="button"
                  onClick={() => {
                    navigate("/signin");
                  }}
                  className="text-gray-500 mr-4"
                >
                  Log In
                </button>
                <button
                  type="submit"
                  className="bg-primary hover:bg-hoverColor  text-white font-bold py-2 px-4 rounded"
                >
                  Sign Up
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default Signup;
