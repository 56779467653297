import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Button from "../../common/Button";
import ModalForm from "./ModalForm";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";


import {
  useGetCourses,
  useAddCourse,
  useGetAverage,
  useLogOut,
  useDeleteCourse,
} from "../../Hooks/teacher.hook";

const CourseComponent = () => {

  const [showModal, setShowModal] = useState(false);
  const [courses, setCourses] = useState(null);
  const [average, setAverage] = useState(0);
  const navigate = useNavigate();

  const {
    data: getCoursesData,
    status: CoursesStatus,
    refetch,
  } = useGetCourses();

  const {
    mutate: deleteCourse,
    status: deleteCourseStatus,
    data: deleteCourseData,
  } = useDeleteCourse();

  const { data: getAverageData, status: getAverageStatus } = useGetAverage();
  const {
    mutate: logOutMutate,
    status: logOutStatus,
    data: logOutData,
  } = useLogOut();

  const {
    mutate,
    status: AddCourseStatus,
    isError,
    data: addCourseData,
  } = useAddCourse();

  useEffect(() => {
    if (CoursesStatus == "success") {
      if (getCoursesData.status == 200) {
        // console.log("result", getCoursesData.data);
        setCourses(getCoursesData.data);
      }
    }
  }, [getCoursesData, CoursesStatus]);

  useEffect(() => {
    if (getAverageStatus == "success") {
      if (getAverageData.status == 200) {
        // console.log("result", getAverageData.data);
        setAverage(getAverageData.data);
      }
    }
  }, [getAverageData, getAverageStatus]);

  // console.log(average.toFixed(3));
  useEffect(() => {
    if (AddCourseStatus == "success") {
      if (addCourseData.status == 201) {
        // console.log("result", addCourseData.data);
        refetch();
      }
    }
  }, [addCourseData, AddCourseStatus]);

  useEffect(() => {
    if (deleteCourseStatus == "success" && deleteCourseData.status == 200) {
      refetch();
      alert("Course deleted successfully");
    }
  }, [deleteCourseData, deleteCourseStatus]);

  useEffect(() => {
    if (logOutStatus == "success" && logOutData.status == 200) {
      navigate("/signin");
    }
  }, [logOutStatus]);

  
  const clickHandler = (id) => {
    navigate(`/previous-feedback/${id}`);
  };
  const handleSubmit = (values) => {
    console.log("values", values);
    mutate(values);
    setShowModal(false);
  };

  const handleDelete = (id) => {
    deleteCourse({ id: id });
  };

  const handleCancel = () => {
    setShowModal(false);
  };

  return (
    <div className="w-full h-auto min-h-full bg-white flex flex-col justify-center items-center lg:items-start px-7 py-20 md:px-0">
       <div className="absolute top-0 left-0 mt-6 ml-6 text-primary"  onClick={() => navigate(-1)}>
      <FontAwesomeIcon icon={faArrowLeft} size="2x" />
    </div>
      <div className=" flex flex-col w-[22rem] sm:w-[30rem] md:w-[40rem] lg:w-[50rem] xl:w-[70rem] md:self-start md:ml-48 ">
        <div className=" flex flex-row justify-between">
          <Button
            className="flex flex-row "
            onClick={() => setShowModal(true)}
            text="Create New Courses"
          />
          <Button
            onClick={() => {
              logOutMutate({});
            }}
            text="Log Out"
          />
        </div>
        <p className="font-normal px-3 md:px-0 py-6 text-[1rem] md:text-[1.5rem] xl:text-[2rem] text-primary">
          Previous Courses:
        </p>
        {courses &&
          (courses.length > 0 ? (
            <ul className="w-[60rem] px-7 md:pl-4 cursor-pointer list-disc list-inside divide-y divide-gray-300">
              {courses &&
                courses.map((course) => {
                  return (
                    <div className="flex justify-between ">
                      <li
                        onClick={() => {
                          clickHandler(course.id);
                        }}
                        className="text-xl p-2 hover:underline"
                      >
                        {course.semester} {course.course_code} -{" "}
                        {course.faculty} - {course.department}
                      </li>
                      <button
                        onClick={() => {
                          handleDelete(course.id);
                        }}
                        className="hover:underline text-xl"
                      >
                        Delete
                      </button>
                    </div>
                  );
                })}
            </ul>
          ) : (
            <p className="text-xl">No Courses Found</p>
          ))}
      </div>
      <ModalForm
        title="Add Batch"
        showModal={showModal}
        onClose={handleCancel}
        onSubmit={handleSubmit}
      />
    </div>
  );
};

export default CourseComponent;
